export const nodeFromData = (datum, included, appendFeed) => {
  const { attributes, relationships } = datum
  // Extract field_image from included array from JSON:API
  const fieldImage = () => {
    const imageData = JSON.parse(attributes.field_cloudinary)
    return imageData[0]
  }

  const alias = appendFeed
    ? "/feed" + attributes.path.alias
    : attributes.path.alias

  let pageData = {
    image: fieldImage(),
    title: attributes.title,
    type: datum.type.replace("--", "__"),
    alias: alias,
    created: attributes.created,
    summary: attributes.body.summary,
    body: attributes.body.processed,
    comment: attributes.comment,
    drupalInternalNid: attributes.drupal_internal__nid,
    drupalId: datum.id,
    fieldImage: JSON.parse(attributes.field_cloudinary),
    fieldLike: attributes.field_like,
    relationships: {
      uid: {
        id: relationships.uid.data.id,
      },
    },
    avatar: included[0].attributes.field_user_avatar,
  }

  const author = relationships.uid.data.id
  included.forEach(function(el) {
    if (el.id === author) {
      pageData.relationships.uid.name = el.attributes.name
      pageData.relationships.uid.field_user_avatar =
        el.attributes.field_user_avatar
      pageData.avatar = el.attributes.field_user_avatar
    }
  })

  // if (!appendFeed) {
  //   const author = relationships.uid.data.id
  //   included.forEach(function(el) {
  //     if (el.id === author) {
  //       pageData.authorName = el.attributes.name
  //       pageData.authorImage = el.attributes.field_user_avatar
  //       // pageData.relationships.uid = [
  //       //   ...pageData.relationships.uid,
  //       //   { name: el.attributes.name },
  //       //   { field_user_avatar: el.attributes.field_user_avatar },
  //       // ]
  //     }
  //   })
  // }

  return pageData
}
