import React, { Component, createContext } from "react"
import Client from "shopify-buy"

const client = Client.buildClient({
  domain: process.env.GATSBY_SHOPIFY_DOMAIN,
  storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN,
})

export const StoreContext = createContext({
  client,
  isCartOpen: false,
  checkout: { lineItems: [] },
  products: [],
  shop: {},
  initiateStore: () => {},
  handleCartOpen: () => {},
  handleCartClose: () => {},
  addVariantToCart: () => {},
  updateQuantityInCart: () => {},
  removeLineItemInCart: () => {},
})

export class StoreState extends Component {
  _isMounted = false
  constructor(props) {
    super(props)

    this.initiateStore = this.initiateStore.bind(this)
    this.handleCartOpen = this.handleCartOpen.bind(this)
    this.handleCartClose = this.handleCartClose.bind(this)
    this.addVariantToCart = this.addVariantToCart.bind(this)
    this.updateQuantityInCart = this.updateQuantityInCart.bind(this)
    this.removeLineItemInCart = this.removeLineItemInCart.bind(this)

    this.state = {
      isCartOpen: false,
      checkout: { lineItems: [] },
      products: [],
      shop: {},
      client: client,
      initiateStore: this.initiateStore,
      handleCartOpen: this.handleCartOpen,
      handleCartClose: this.handleCartClose,
      addVariantToCart: this.addVariantToCart,
      updateQuantityInCart: this.updateQuantityInCart,
      removeLineItemInCart: this.removeLineItemInCart,
    }
  }

  componentDidMount() {
    this._isMounted = true
    // this.state.initiateStore()
    // this.state.client.checkout.create().then(res => {
    //   this._isMounted &&
    //     this.setState({
    //       checkout: res,
    //     })
    // })

    // Fetch ALL store products
    // this.state.client.product.fetchAll().then(res => {
    //   this._isMounted &&
    //     this.setState({
    //       products: res,
    //     })
    // })

    // this.state.client.product.fetchAll().then(res => {
    //   console.log(res)
    // })

    // Fetch Default collection for shop
    // this.state.client.collection.fetchAllWithProducts().then(res => {
    //   this._isMounted &&
    //     this.setState({
    //       products: res[0].products,
    //     })
    // })
    //
    // this.state.client.shop.fetchInfo().then(res => {
    //   this._isMounted &&
    //     this.setState({
    //       shop: res,
    //     })
    // })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { checkout, client } = this.state
    // if (
    //   prevState.checkout.lineItems.length !== checkout.lineItems.length &&
    //   checkout.lineItems.length === 0
    // ) {
    //   setTimeout(() => this.state.handleCartClose(), 350)
    // }

    if (prevState.client !== client) {
      this._isMounted && this.setState({ client: client })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.state !== nextState
  }

  initiateStore = () => {
    // Only Initiate Once
    if (this.state.checkout.id) return

    // Fetch Checkout & Default collection for shop
    this.state.client.checkout.create().then((checkout) => {
      this.state.client.collection.fetchAllWithProducts().then((res) => {
        this._isMounted &&
        this.setState({
          checkout: checkout,
          products: res[0].products,
        })
      })
    })

    // this.state.client.shop.fetchInfo().then(res => {
    //   this._isMounted &&
    //   this.setState({
    //     shop: res,
    //   })
    // })
  }

  addVariantToCart = (variantId, quantity, customAttributes = []) => {
    if (!this._isMounted) return

    this.handleCartOpen()

    const lineItemsToAdd = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
        customAttributes: customAttributes,
      },
    ]

    /*
      customAttributes to be added to products in the following format
        [
          {
            key: "Boat",
            value: "Reef Runner 23CC",
          },
          {
            key: "Listing URL",
            value: "https://centerconsolesonly.net/listings/rr23cc",
          },
        ],
     */

    // const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }]
    const checkoutId = this.state.checkout.id

    return this.state.client.checkout
      .addLineItems(checkoutId, lineItemsToAdd)
      .then((res) => {
        this._isMounted &&
          this.setState({
            checkout: res,
          })
      })
  }

  updateQuantityInCart = (lineItemId, quantity) => {
    if (!this._isMounted) return

    const checkoutId = this.state.checkout.id
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ]

    return this.state.client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then((res) => {
        this._isMounted &&
          this.setState({
            checkout: res,
          })
      })
  }

  removeLineItemInCart = (lineItemId) => {
    if (!this._isMounted) return

    const checkoutId = this.state.checkout.id

    return this.state.client.checkout
      .removeLineItems(checkoutId, [lineItemId])
      .then((res) => {
        this._isMounted &&
          this.setState({
            checkout: res,
            isCartOpen: res.lineItems[0] !== undefined
          })
      })
  }

  handleCartOpen = () => {
    this._isMounted &&
      this.setState({
        isCartOpen: true,
      })
  }

  handleCartClose = ()  => {
    this._isMounted &&
      this.setState({
        isCartOpen: false,
      })
  }

  render() {
    // console.log(this.state)
    return (
      <StoreContext.Provider value={this.state}>
        {this.props.children}
      </StoreContext.Provider>
    )
  }
}

export const withStoreContext = (Component) => {
  return (props) => (
    <StoreContext.Consumer>
      {(context) => <Component {...props} storeContext={context} />}
    </StoreContext.Consumer>
  )
}
