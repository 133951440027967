import React from "react"
import axios from "axios"
import { nodeFromData } from "../utils/normalizeInfiniteScroll"

const baseUrl = `${process.env.GATSBY_API_URL}`
const endpoint = "/jsonapi/node/community"
/*
 * The reason we use Global State instead of Component State is that
 * when the user clicks something on the main page and then clicks back,
 * we don't want to reset the user's scroll position. If we don't maintain
 * state, then we will "lose" some of the items when the user clicks
 * back and the state resets, which obviously resets scroll position as well.
 */
export const GlobalStateContext = React.createContext({
  cursor: 0 /* Which page infinite scroll should fetch next. */,
  useInfiniteScroll: true /* Toggle between pagination and inf. scroll for this demo & fallback in case of error. */,
  isInitializing: () => {
    return true
  },
  updateState: () => {},
  hasMore: () => {},
  loadMore: () => {},
  latestFeed: () => {},
})

export class GlobalState extends React.Component {
  constructor(props) {
    super(props)

    console.log("*** Constructing Global State ***")

    this.hasMore = this.hasMore.bind(this)
    this.loadMore = this.loadMore.bind(this)
    this.latestFeed = this.latestFeed.bind(this)
    this.updateState = this.updateState.bind(this)
    this.isInitializing = this.isInitializing.bind(this)

    /* State also contains metadata for items, e.g. state["page81"] (only contains keys for _received_ metadata) */
    this.state = {
      cursor: 0,
      useInfiniteScroll: true,
      isInitializing: this.isInitializing,
      updateState: this.updateState,
      hasMore: this.hasMore,
      loadMore: this.loadMore,
      latestFeed: this.latestFeed,
    }
  }

  isInitializing = () => {
    return this.state.cursor === 0
  }

  updateState = mergeableStateObject => {
    this.setState(mergeableStateObject)
  }

  loadMore = () => {
    const pageNum = this.state.cursor
    console.log("Fetching metadata for page " + pageNum)
    this.setState(state => ({ cursor: state.cursor + 1 })) // TODO: make sure this is guaranteed to set state before another loadMore may be able to fire!
    axios.get(`${__PATH_PREFIX__}/paginationJson/${pageNum}.json`).then(
      res => {
        this.setState({
          ["page" + pageNum]: res.data,
        })
      },
      error => {
        this.setState({
          useInfiniteScroll: false, // Fallback to Pagination on error.
        })
      }
    )
  }

  hasMore = pageContext => {
    if (!this.state.useInfiniteScroll) return false
    if (this.isInitializing()) return true
    return this.state.cursor <= pageContext.countPages
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    prevState.page1 === undefined && this.state.page1 && this.latestFeed()
  }

  latestFeed = () => {
    let self = this
    const { page1 } = self.state
    const latestPost = page1[0].created
    axios
      .get(baseUrl + endpoint, {
        params: {
          "filter[date-filter][condition][path]": "created",
          "filter[date-filter][condition][operator]": ">",
          "filter[date-filter][condition][value]":
            new Date(latestPost).getTime() / 1000,
          include: "uid",
        },
      })
      .then(function(response) {
        const newPosts = self.processData(response.data)
        newPosts.length > 0 &&
          self.setState({
            ["page1"]: [...newPosts, ...page1],
          })
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  processData(data) {
    const d = data.data
    let posts = []
    if (d.length > 0) {
      const included = data.included
      for (let i = 0; i < d.length; i++) {
        posts[i] = nodeFromData(d[i], included, true)
      }
    }
    return posts.reverse()
  }

  render() {
    // console.log(this.state)
    return (
      <GlobalStateContext.Provider value={this.state}>
        {this.props.children}
      </GlobalStateContext.Provider>
    )
  }
}
