import React from "react"
import { GlobalState } from "./src/components/globalState.js"
import { StoreState } from "./src/context/StoreContext"

// Main App stylesheet
import "./src/assets/scss/material-kit-pro-react.css"
// Shop Stylesheet
import "./src/components/Shop/shop.css"

// Implements wrapRootElement for Infinite Scroll's globalState
export const wrapRootElement = ({ element }) => {
  return (
    <StoreState>
      <GlobalState>{element}</GlobalState>
    </StoreState>
  )
}

// Refresh when service worker updates
// export const onServiceWorkerUpdateReady = () => {
//   const answer = window.confirm(
//     `This application has been updated. ` +
//       `Reload to display the latest version?`
//   )
//
//   if (answer === true) {
//     window.location.reload()
//   }
// }
